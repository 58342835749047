import React from 'react'
import Mission from '../components/Mission';
import Team from '../components/Team';

const About = () => {
  return (
    <>
    <Mission />
    <Team />
    </>
  )
}

export default About