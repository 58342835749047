import React, { useState } from 'react';
import { Box, Button, Stack, TextField, Grid } from '@mui/material';
import Title from './Title';
import Paragraph from './Paragraph';

const Details = () => {
    const [submitted, setSubmitted] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const apiUrl = process.env.REACT_APP_API_URL;
        const calendlyUrl = process.env.REACT_APP_CALENDLY_URL;

        try {
            const response = await fetch(`${apiUrl}/save-details`, {
                method: 'POST',
                body: data,
            });

            if (response.ok) {
                setSubmitted(true);
                // window.open(calendlyUrl, '_blank');
            } else {
                alert('Failed to save data');
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            alert('An error occurred while submitting the form');
        }
    };

    return (
        <Stack
            component='section'
            direction="column"
            justifyContent='center'
            alignItems='center'
            sx={{
                py: 10,
                px: 2,
            }}
        >
            <Title
                text={'Request a Demo'}
                textAlign={'center'}
            />
            <Paragraph
                text={
                    'Daicus is committed to help businesses with swift data confidence using AI. \
                    Schedule a meeting with our founding team today.'
                }
                maxWidth={'sm'}
                mx={0}
                textAlign={'center'}
            />

            <Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{
                    mt: 1,
                    py: 2
                }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="firstName"
                            label="First Name"
                            name="firstName"
                            autoComplete="given-name"
                            autoFocus
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="lastName"
                            label="Last Name"
                            name="lastName"
                            autoComplete="family-name"
                        />
                    </Grid>
                </Grid>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Work Email"
                    name="email"
                    autoComplete="email"
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="jobTitle"
                    label="Job Title"
                    name="jobTitle"
                    autoComplete="job-title"
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="companyName"
                    label="Company Name"
                    name="companyName"
                    autoComplete="company-name"
                />
                <Button
                    variant="contained"
                    fullWidth
                    type="submit"
                    size="medium"
                    disabled={submitted}
                    sx={{
                        fontSize: '0.9rem',
                        textTransform: 'capitalize',
                        py: 2,
                        mt: 3,
                        mb: 2,
                        borderRadius: 0,
                        backgroundColor: '#14192d',
                        "&:hover": {
                            backgroundColor: '#1e2a5a',
                        }
                    }}
                >
                    {submitted ? 'Success! Talk to You Soon.' : 'Request Your Demo'}
                </Button>
            </Box>
        </Stack>
    );
};

export default Details;
