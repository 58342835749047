// src/components/Testimonial.jsx
import React from 'react';
import { Box, Typography, Avatar, Card, Grid, CardContent } from '@mui/material';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import AvatarPeng from '../assets/avatar_peng_du.jpeg'

const testimonials = [
  {
    headshot: AvatarPeng,
    name: 'Peng Du, Ph.D.',
    title: 'Co-Founder and CEO',
    company: 'Apexiel',
    statement: 'Daicus\'s chatbot and RAG consulting service was amazing! \
              They really took the time to understand our use cases and helped us map out a clear AI solution. \
              With their easy-to-follow advice and hands-on support, we turned out idea into a working service in just two months, \
              debuting at the largest rallycross event in the US. We couldn\'t have done it without the Daicus Team!',
  },
//   {
//     headshot: AvatarZwea,
//     name: 'Jane Smith',
//     title: 'CEO',
//     company: 'Company B',
//     statement: 'I love using this tool for my projects!',
//   },
  // Add more testimonials as needed
];

const Testimonial = () => {
    const settings = {
      dots: true,
      infinite: testimonials.length > 1,
      speed: 1500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000,
    };
  
    return (
      <Box sx={{ padding: 4, background: 'linear-gradient(to right, #e3f2fd, #e1bee7, #f8bbd0)' }}>
        <Typography variant="h4" gutterBottom align="center" sx={{ fontWeight: 'bold', mb: 4, mt: 8}}>
          Testimonials
        </Typography>
        <Box sx={{mb: 10}}>
            <Slider {...settings}>
            {testimonials.map((testimonial, index) => (
                <Box key={index} sx={{ padding: 2}}>
                <Card sx={{ maxWidth: 600, margin: 'auto', backgroundColor: '#ffffffcc' }}>
                    <CardContent>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item>
                        <Avatar
                            alt={testimonial.name}
                            src={testimonial.headshot}
                            sx={{ width: 80, height: 80 }}
                        />
                        </Grid>
                        <Grid item xs>
                        <Typography variant="h6">{testimonial.name}</Typography>
                        <Typography variant="body" color="textSecondary">
                            {testimonial.title}, {testimonial.company}
                        </Typography>
                        <Typography variant="body1" sx={{ marginTop: 1, fontStyle: 'italic'}}>
                            "{testimonial.statement}"
                        </Typography>
                        </Grid>
                    </Grid>
                    </CardContent>
                </Card>
                </Box>
            ))}
            </Slider>
        </Box>
      </Box>
    );
};

export default Testimonial;
