import React, { useEffect, useState } from 'react';
import { Card, CardActionArea, CardContent, CardMedia, Typography } from '@mui/material';


const Blog = () => {
    const [blogPosts, setBlogPosts] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/blogs`);
                const data = await response.json();
                setBlogPosts(data);
            } catch (error) {
                console.error('Error fetching data: ', error);
            }
        };

        fetchData();
    }, []);


    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', padding: '20px' }}>
            {[...blogPosts].reverse().map(post => (
                <Card key={post.id} sx={{ display: 'flex', width: '98%',margin: '0 auto', marginBottom: 1 }}>
                    <CardActionArea href={post.readMoreUrl} sx={{ display: 'flex', width: '100%', alignItems: 'flex-start' }}>
                        <CardMedia
                            component="img"
                            sx={{ width: '25%' }}
                            image={post.imageUrl}
                            alt="Blog Post"
                        />
                        <CardContent  sx={{ flex: '1', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                            <Typography variant="body2" color="text.secondary">
                                {post.date}
                            </Typography>
                            <Typography gutterBottom variant="h5" component="div" sx={{ fontWeight: 'bold' }}>
                                {post.title}
                            </Typography>
                            <Typography variant="body1">
                                {post.summary}
                            </Typography>
                            
                        </CardContent>
                    </CardActionArea>
                </Card>
            ))}
        </div>
    );
}

export default Blog;