// import React, { useState } from 'react'
// import {  
//   styled, 
//   useTheme,
//   Drawer,
//   Divider,
//   List,
//   IconButton,
//   ListItem,
//   ListItemIcon,
//   ListItemText,
// } from '@mui/material'
// // rotas
// import { Link } from 'react-router-dom';
// // icons
// import EmailIcon from '@mui/icons-material/Email';
// import HomeIcon from '@mui/icons-material/Home';
// import InfoIcon from '@mui/icons-material/Info';
// import MenuIcon from '@mui/icons-material/Menu';
// import RssFeedIcon from '@mui/icons-material/RssFeed';
// import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
// import ChevronRightIcon from '@mui/icons-material/ChevronRight';

// const drawerWidth = 240;

// const DrawerHeader = styled('div')(({ theme }) => ({
//   display: 'flex',
//   alignItems: 'center',
//   padding: theme.spacing(0, 5),
//   ...theme.mixins.toolbar,
//   justifyContent: 'flex-start',
// }));

// //rotas
// const itemList = [
//   {
//     text: "Home",
//     icon: <HomeIcon />,
//     to: "/"
//   },
//   {
//     text: "About",
//     icon: <InfoIcon />,
//     to: "/about"
//   },
//   {
//     text: "Contact",
//     icon: <EmailIcon />,
//     to: "/contact"
//   },
//   {
//     text: "Blog",
//     icon: <RssFeedIcon />,
//     to: "/blog"
//   }
// ];


// const DrawerItem = () => {

//   const theme = useTheme();
//   const [open, setOpen] = useState(false);
//   const handleDrawerOpen = () => {
//     setOpen(true);
//   };

//   const handleDrawerClose = () => {
//     setOpen(false);
//   };

//   return (
//     <>
//       <IconButton
//       color="inherit"
//       aria-label="open drawer"
//       edge="end"
//       onClick={handleDrawerOpen}
//       sx={{ ...(open && { display: 'none' }) }}
//       >
//         <MenuIcon />
//       </IconButton>

//       <Drawer
//       sx={{
//           flexGrow: 1,
//           flexShrink: 0,
//           '& .MuiDrawer-paper': {
//           width: drawerWidth,
//           },
//       }}
//       variant="persistent"
//       anchor="right"
//       open={open}
//       >
//         <DrawerHeader>
//           <IconButton onClick={handleDrawerClose}>
//             {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
//           </IconButton>
//         </DrawerHeader>
//         <Divider />
//         <List>
//           {itemList.map( ( item ) => {
//             const { text } = item;
//             return(
//               <ListItem 
//               key={text} 
//               component={Link} 
//               to={item.to}
//               sx={{
//                 color: '#414141',
//                 "&:hover": {
//                   backgroundColor: '#e9e5e5',
//                   color: '#1c2859',
//                 }
//               }}
//               >
//                 <ListItemIcon
//                 sx={{
//                   "&:hover":{
//                     backgroundColor: 'transparent',
//                     color: '#1c2859',
//                   }
//                 }}
//                 >
//                   {item.icon}
//                 </ListItemIcon>
//                 <ListItemText primary={text} />
//               </ListItem>
//             )
//           })}
//         </List>
//       </Drawer>
//     </>
//   )
// }

// export default DrawerItem;

import React, { useState } from 'react'
import {  
  styled, 
  useTheme,
  Drawer,
  Divider,
  List,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse
} from '@mui/material'
// rotas
import { Link } from 'react-router-dom';
// icons
import EmailIcon from '@mui/icons-material/Email';
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import MenuIcon from '@mui/icons-material/Menu';
import RssFeedIcon from '@mui/icons-material/RssFeed';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import LabelOutlinedIcon from '@mui/icons-material/LabelOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';

const drawerWidth = 240;

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 5),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}));

//rotas
const itemList = [
  {
    text: "Home",
    icon: <HomeIcon />,
    to: "/"
  },
  {
    text: "About",
    icon: <InfoIcon />,
    to: "/about"
  },
  {
    text: "Contact",
    icon: <EmailIcon />,
    to: "/contact"
  },
  {
    text: "Blog",
    icon: <RssFeedIcon />,
    to: "/blog"
  }
];

const productItems = [
  {
    text: "AI Data Verification Tool",
    to: "/contact",
    icon: <LabelOutlinedIcon />
  },
  {
    text: "AI Data Extraction Tool (Coming Soon)",
    to: "/contact",
    icon: <LabelOutlinedIcon />
  }
];

const serviceItems = [
  {
    text: "Data Verification Report & Consulting",
    to: "/contact",
    icon: <AssessmentOutlinedIcon />
  },
  {
    text: "Custom GPT Building & Consulting",
    to: "/contact",
    icon: <AssessmentOutlinedIcon />
  },
  {
    text: "GPT Fine-tuning & Consulting",
    to: "/contact",
    icon: <AssessmentOutlinedIcon />
  },
  {
    text: "Prompt Engineering & RAG Consulting",
    to: "/contact",
    icon: <AssessmentOutlinedIcon />
  }
];

const DrawerItem = () => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [productsOpen, setProductsOpen] = useState(false);
  const [servicesOpen, setServicesOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleProductsClick = () => {
    setProductsOpen(!productsOpen);
  };

  const handleServicesClick = () => {
    setServicesOpen(!servicesOpen);
  };

  return (
    <>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="end"
        onClick={handleDrawerOpen}
        sx={{ color: '#002884', ...(open && { display: 'none' }) }}  // Change the color to your desired blue
      >
        <MenuIcon />
      </IconButton>

      <Drawer
        sx={{
          flexGrow: 1,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
          },
        }}
        variant="persistent"
        anchor="right"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {itemList.map((item) => {
            const { text } = item;
            return (
              <ListItem
                key={text}
                component={Link}
                to={item.to}
                sx={{
                  color: '#414141',
                  "&:hover": {
                    backgroundColor: '#e9e5e5',
                    color: '#1c2859',
                  }
                }}
              >
                <ListItemIcon
                  sx={{
                    "&:hover": {
                      backgroundColor: 'transparent',
                      color: '#1c2859',
                    }
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItem>
            );
          })}
          <ListItem button onClick={handleProductsClick}>
            <ListItemIcon>
              <LabelOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Products" />
            {productsOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={productsOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {productItems.map((product) => (
                <ListItem
                  key={product.text}
                  component={Link}
                  to={product.to}
                  sx={{
                    pl: 4,
                    color: '#414141',
                    "&:hover": {
                      backgroundColor: '#e9e5e5',
                      color: '#1c2859',
                    }
                  }}
                >
                  <ListItemIcon
                    sx={{
                      "&:hover": {
                        backgroundColor: 'transparent',
                        color: '#1c2859',
                      }
                    }}
                  >
                    {product.icon}
                  </ListItemIcon>
                  <ListItemText primary={product.text} />
                </ListItem>
              ))}
            </List>
          </Collapse>
          <ListItem button onClick={handleServicesClick}>
            <ListItemIcon>
              <AssessmentOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Services" />
            {servicesOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={servicesOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {serviceItems.map((service) => (
                <ListItem
                  key={service.text}
                  component={Link}
                  to={service.to}
                  sx={{
                    pl: 4,
                    color: '#414141',
                    "&:hover": {
                      backgroundColor: '#e9e5e5',
                      color: '#1c2859',
                    }
                  }}
                >
                  <ListItemIcon
                    sx={{
                      "&:hover": {
                        backgroundColor: 'transparent',
                        color: '#1c2859',
                      }
                    }}
                  >
                    {service.icon}
                  </ListItemIcon>
                  <ListItemText primary={service.text} />
                </ListItem>
              ))}
            </List>
          </Collapse>
        </List>
      </Drawer>
    </>
  );
}

export default DrawerItem;