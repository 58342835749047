import React from 'react'
import GetInTouch from '../components/GetInTouch';
import UseCase from '../components/UseCase';
import Header from '../components/Header';
import Product from '../components/Product';
import Feature from '../components/Feature';
import Testimonial from '../components/Testimonial';

const Home = () => {
  return (
    <>
    <Header />
    <UseCase />
    <Product />
    <Feature />
    <Testimonial />
    <GetInTouch />
    </>

  )
}

export default Home