import React from 'react'
import { 
    Box,
    Grid,
    styled,
    Typography,
} from '@mui/material'
import Title from './Title'
// img
import imgDetail from '../assets/use_case_data_accuracy.png';
import imgDetail2 from '../assets/use_case_team_collaboration.png';
import imgDetail3 from '../assets/use_case_insights_accuracy.png';

const UseCase = () => {

    const CustomGridItem = styled(Grid) ({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    })
    
    const CustomTypography = styled(Typography) ({
        fontSize: '1.1rem',
        textAlign: 'start',
        lineHeight: '1.5',
        color: '#515151',
        marginTop: '1.5rem',
    })

    return (

        <Box sx={{ py: 10, px: 16 }}>
            <Typography variant="h3" component="h2" sx={{ textAlign: 'center', mb: 4 }}>
                Use Cases
            </Typography>

            <Grid container spacing={{ xs: 4, sm: 4, md: 14 }}   
            sx={{
                py: 10,
                px: 2,
                
            }}
            >
                <CustomGridItem item xs={12} sm={8} md={6} 
                component = 'section'
                sx={{
                    order: {xs: 1, sm: 1, md: 1}
                }}
                >
                    <Box component='article'
                    sx={{
                        px: 4,
                    }}
                    >
                        <Title
                        text={
                            'Data Correctness'
                        }
                        textAlign={'center'}
                        />
                        <CustomTypography variant="h6" component="h2" sx={{ textAlign: 'center'}}>
                            Validating data correctness in tables and reports,
                            eliminating expensive and time-consuming manual work.
                        </CustomTypography> 
                    </Box>

                </CustomGridItem>
                
                <Grid item xs={12} sm={4} md={6} 
                sx={{
                    order: {xs: 2, sm: 2, md: 2}
                }}>
                    <img src={imgDetail} alt="" 
                    style={{
                        width: '30%', // Adjust the width as needed
                        display: 'block',
                        margin: '0 auto', // Center the image horizontally
                    }}
                    />
                </Grid>

                <Grid item xs={12} sm={4} md={6}
                sx={{
                    order: {xs: 4, sm: 4, md: 3}
                }}
                >
                    <img src={imgDetail2} alt="" 
                    style={{ 
                        width: "30%",
                        display: 'block',
                        margin: '0 auto', // Center the image horizontally
                    }}
                    />
                </Grid>

                <CustomGridItem item xs={12} sm={8} md={6}
                sx={{
                    order: {xs: 3, sm: 3, md: 4}
                }}
                >
                    <Box component='article'
                    sx={{
                        px: 4,
                    }}
                    >
                        <Title
                        text={
                            'Team Collaboration'
                        }
                        textAlign={'center'}
                        />
                        <CustomTypography variant="h6" component="h2" sx={{ textAlign: 'center'}}>
                            Help teams and organizations deliver verified data without endless turnarounds, blames, 
                            and misunderstanding.
                        </CustomTypography>
                    </Box>
                </CustomGridItem>

                {/* Third Row */}
                <CustomGridItem item xs={12} sm={8} md={6} component='section'
                sx={{
                    order: {xs: 5, sm: 5, md: 5}
                }}>
                    <Box component='article' sx={{ px: 4 }}>
                        <Title text={' Insights Accuracy'} textAlign={'center'} />
                        <CustomTypography variant="h6" component="h2" sx={{ textAlign: 'center'}}>
                            Ensure extracted insights accurate and consistent with sources, avoiding potential 
                            trust and financial losses.
                        </CustomTypography>
                    </Box>
                </CustomGridItem>

                <Grid item xs={12} sm={4} md={6}
                sx={{
                    order: {xs: 6, sm: 6, md: 6}
                }}>
                    <img src={imgDetail3} alt="" 
                        style={{
                            width: '30%', // Adjust the width as needed
                            display: 'block',
                            margin: '0 auto', // Center the image horizontally
                        }}
                    />
                </Grid>


            </Grid>
        </Box>

    )
}

export default UseCase;