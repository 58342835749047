import React, { useEffect, useState } from 'react';
import { TextField, Button, Typography, CircularProgress, Container, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

function DataPage() {
    const [data, setData] = useState([]);
    const [accessKey, setAccessKey] = useState('');
    const [isFetching, setIsFetching] = useState(false);

    useEffect(() => {
        if (accessKey && isFetching) {
            fetch(`${process.env.REACT_APP_API_URL}/form-data`, {
                method: 'GET', 
                headers: {
                    'Authorization': accessKey
                }
            })
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Failed to fetch data');
            })
            .then(data => {
                setData(data);
                setIsFetching(false);
            })
            .catch(error => {
                console.log('Error:', error);
                setIsFetching(false);
            });
        }
    }, [accessKey, isFetching]);

    const handleInputChange = (e) => {
        setAccessKey(e.target.value);
    };

    const handleSubmit = () => {
        setIsFetching(true);
    };

    return (
        <Container maxWidth="md">
            <Box sx={{ mt: 4 }}>
                <Typography variant="h4" gutterBottom>Data Page</Typography>
                <TextField
                    fullWidth
                    variant="outlined"
                    label="Enter Password"
                    type="password"
                    value={accessKey}
                    onChange={handleInputChange}
                    margin="normal"
                />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    sx={{ mt: 2 }}
                >
                    Submit
                </Button>
                <Box sx={{ mt: 4 }}>
                    {isFetching ? (
                        <CircularProgress />
                    ) : (
                        data.length > 0 ? (
                            <Box sx={{ marginBottom: 4}}>
                            <TableContainer component={Paper} sx={{ marginTop: '20px' }}>
                                <Table sx={{ minWidth: 650 }}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>First Name</TableCell>
                                            <TableCell>Last Name</TableCell>
                                            <TableCell>Email</TableCell>
                                            <TableCell>Job Title</TableCell>
                                            <TableCell>Company Name</TableCell>
                                            <TableCell>Submission Time</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data.map((entry, index) => (
                                            <TableRow key={index}>
                                                <TableCell sx={{ whiteSpace: 'nowrap', overflow: 'hidden' }}>{entry.firstName}</TableCell>
                                                <TableCell sx={{ whiteSpace: 'nowrap', overflow: 'hidden' }}>{entry.lastName}</TableCell>
                                                <TableCell sx={{ whiteSpace: 'nowrap', overflow: 'hidden' }}>{entry.email}</TableCell>
                                                <TableCell sx={{ whiteSpace: 'nowrap', overflow: 'hidden' }}>{entry.jobTitle}</TableCell>
                                                <TableCell sx={{ whiteSpace: 'nowrap', overflow: 'hidden' }}>{entry.companyName}</TableCell>
                                                <TableCell sx={{ whiteSpace: 'nowrap', overflow: 'hidden' }}>{entry.submissionTime}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    ) : (
                        <Typography variant="body1">No data available</Typography>
                    )
                    )}
                </Box>
            </Box>
        <Box sx={{ height: '100px' }} />
        </Container>
    );
}

export default DataPage;
