import React from'react';
//rotas
import {BrowserRouter, Routes, Route} from 'react-router-dom';
//pages
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import DataPage from './pages/DataPage'; 
import Blog from './pages/Blog';
import BlogPost from './pages/BlogPost';
import BlogEditor from './pages/BlogEditor';
import BlogManager from './pages/BlogManager';
//componentes
import Navbar from './components/Navbar';
import Footer from './components/Footer/Footer';

function App() {
  return (
    <>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/internal-data' element={<DataPage />} />
          <Route path='/blog' element={<Blog />} />
          <Route path='/blog/:blogName' element={<BlogPost />} />
          <Route path='/blog-editor' element={<BlogEditor />} />
          <Route path="/blog-editor/:blogId" element={<BlogEditor />} /> 
          <Route path='/blog-manager' element={<BlogManager />} />
        </Routes>
      </BrowserRouter>
      <Footer />
    </>
  );
}

export default App;