import React, { useState } from 'react'
import {
    AppBar,
    Toolbar,
    Box,
    List,
    ListItem,
    Typography, 
    styled,
    ListItemButton,
    ListItemText,
    Menu,
    MenuItem,
    ListItemIcon
} from '@mui/material';
import LabelOutlinedIcon from '@mui/icons-material/LabelOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
// menu
import DrawerItem from './DrawerItem';
// rotas
import { Link } from 'react-router-dom';


// personalizacao
const StyledToolbar = styled(Toolbar) ({
    display: 'flex',
    justifyContent: 'space-between',
});

const ListMenu = styled(List)(({ theme }) => ({
    display: 'none',
    [theme.breakpoints.up("sm")] : {
        display: "flex",
    },
}));

// Gradient text style
const GradientText = styled(Typography)({
    background: 'linear-gradient(135deg, #007AFF, #5856D6, #FF2D55)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontWeight: 700,
    fontFamily: '"Poppins", sans-serif', // Use the Poppins font
    textDecoration: 'none', // Ensure no underline for link
});

//rotas
const itemList = [
    {
      text: "Home",
      to: "/" 
    },
    {
        text: "Products",
        to: "/products"
    },
    {
        text: "Services",
        to: "/services"
    },
    {
      text: "About",
      to: "/about"
    },
    {
        text: "Contact",
        to: "/contact"
    },
    {
        text: "Blog",
        to: "/blog"
    }
];

const productItems = [
    {
        text: "AI Data Verification Tool",
        to: "/contact",
        icon: <LabelOutlinedIcon />
    },
    {
        text: "AI Data Extraction Tool (Coming Soon)",
        to: "/contact",
        icon: <LabelOutlinedIcon />
    }
];

const serviceItems = [
    {
        text: "Data Verification Report & Consulting",
        to: "/contact",
        icon: <AssessmentOutlinedIcon />
    },
    {
        text: "Enterprise Chatbot Consulting",
        to: "/contact",
        icon: <AssessmentOutlinedIcon />
    },
    {
        text: "Custom GPT Building & Consulting",
        to: "/contact",
        icon: <AssessmentOutlinedIcon />
    },
    {
        text: "GPT Fine-tuning & Consulting",
        to: "/contact",
        icon: <AssessmentOutlinedIcon />
    },
    {
        text: "Prompt Engineering & RAG Consulting",
        to: "/contact",
        icon: <AssessmentOutlinedIcon />
    }
];

const Navbar = () => {
    const [productsAnchorEl, setProductsAnchorEl] = useState(null);
    const [servicesAnchorEl, setServicesAnchorEl] = useState(null);

    const handleProductsMenuClick = (event) => {
        setProductsAnchorEl(event.currentTarget);
    };

    const handleServicesMenuClick = (event) => {
        setServicesAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setProductsAnchorEl(null);
        setServicesAnchorEl(null);
    };

    return (
        <AppBar 
        component="nav" 
        position="sticky"
        sx={{ 
            background: 'rgba(255, 255, 255, 0.7)', // Semi-transparent white background
            backdropFilter: 'blur(10px)', // Blur effect
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
        }}
        elevation={0}
        >
            <StyledToolbar>
                <Box component={Link} to="/" sx={{ textDecoration: 'none' }}>
                    <GradientText
                        variant="h5"
                        component="h2"
                    >
                        DAICUS
                    </GradientText>
                </Box>
                <Box sx={{display: { xs: 'block', sm: 'none' } }}>
                    <DrawerItem /> 
                </Box>
                <ListMenu>
                    {itemList.map( ( item ) => {
                        const { text } = item;
                        if (text === "Products") {
                            return (
                                <ListItem key={text}>
                                    <ListItemButton
                                        sx={{
                                            color: '#002884',
                                            "&:hover": {
                                                backgroundColor: 'transparent',
                                                color: '#757ce8',
                                            }
                                        }}
                                        onClick={handleProductsMenuClick}
                                    >
                                        <ListItemText primary={text} />
                                    </ListItemButton>
                                    <Menu
                                        anchorEl={productsAnchorEl}
                                        open={Boolean(productsAnchorEl)}
                                        onClose={handleMenuClose}
                                        MenuListProps={{
                                            'aria-labelledby': 'basic-button',
                                        }}
                                    >
                                        {productItems.map((product) => (
                                            <MenuItem
                                                key={product.text}
                                                component={Link}
                                                to={product.to}
                                                onClick={handleMenuClose}
                                                sx={{
                                                    color: '#002884',
                                                    "&:hover": {
                                                        backgroundColor: 'transparent',
                                                        color: '#757ce8',
                                                    }
                                                }}
                                            >
                                                <ListItemIcon>
                                                    {product.icon}
                                                </ListItemIcon>
                                                {product.text}
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                </ListItem>
                            );
                        }
                        if (text === "Services") {
                            return (
                                <ListItem key={text}>
                                    <ListItemButton
                                        sx={{
                                            color: '#002884',
                                            "&:hover": {
                                                backgroundColor: 'transparent',
                                                color: '#757ce8',
                                            }
                                        }}
                                        onClick={handleServicesMenuClick}
                                    >
                                        <ListItemText primary={text} />
                                    </ListItemButton>
                                    <Menu
                                        anchorEl={servicesAnchorEl}
                                        open={Boolean(servicesAnchorEl)}
                                        onClose={handleMenuClose}
                                        MenuListProps={{
                                            'aria-labelledby': 'basic-button',
                                        }}
                                    >
                                        {serviceItems.map((service) => (
                                            <MenuItem
                                                key={service.text}
                                                component={Link}
                                                to={service.to}
                                                onClick={handleMenuClose}
                                                sx={{
                                                    color: '#002884',
                                                    "&:hover": {
                                                        backgroundColor: 'transparent',
                                                        color: '#757ce8',
                                                    }
                                                }}
                                            >
                                                <ListItemIcon>
                                                    {service.icon}
                                                </ListItemIcon>
                                                {service.text}
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                </ListItem>
                            );
                        }
                        return(
                            <ListItem key={text}>
                                <ListItemButton component={Link} to={item.to}
                                sx={{
                                    color: '#002884',
                                    "&:hover": {
                                        backgroundColor: 'transparent',
                                        color: '#757ce8',
                                    }
                                }}
                                >
                                    <ListItemText primary={text} />
                                </ListItemButton>
                            </ListItem>
                        )
                    })}
                </ListMenu>
            </StyledToolbar>
        </AppBar>
    )
}

export default Navbar;
