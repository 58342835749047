import React from 'react'
import {  
    Box, 
    Button,
    styled,
    Grid,
    Typography
} from '@mui/material'
import { Link } from 'react-router-dom'

const GradientBox = styled(Box)({
    minHeight: '50vh',
    display: 'flex',
    alignItems: 'center',
    background: 'linear-gradient(135deg, #4b0082, #4682b4)',
    color: '#fff',
    padding: '2rem',
});

const TextContainer = styled(Box)({
    maxWidth: '600px',
    margin: '0 auto',
    textAlign: 'center', // Center the text
});

const ButtonContainer = styled(Box)({
    display: 'flex',
    justifyContent: 'center', // Center the button
    marginTop: '2rem', // Add some spacing between text and button
});

const Mission = () => {

    return (
        <GradientBox>
            <Grid container spacing={4} alignItems="center" justifyContent="center">
                <Grid item xs={12}>
                    <TextContainer>
                        <Typography variant="h4" component="h1" sx={{ fontWeight: 'bold', mb: 2}}>
                            Our mission is to empower businesses for swift data confidence with intuitive AI tools.
                        </Typography>
                        <Typography variant="body1" component="h2">
                            We are creating simple data verification tools by using AI to do complex tasks.
                        </Typography>
                    </TextContainer>
                    <ButtonContainer>
                        <Button 
                            component={Link} 
                            to={'/contact'}
                            variant='contained'
                            sx={{
                                mt: 4,
                                mr: 2,
                                px: 4, 
                                py: 1,
                                fontSize: '0.9rem',
                                textTransform: 'capitalize',
                                borderRadius: 0,
                                borderColor: '#14192d',
                                color: '#fff',
                                backgroundColor: '#7986cb',
                                "&&:hover": {
                                    backgroundColor: "#2c387e"
                                },
                                "&&:focus": {
                                    backgroundColor: "#2c387e"
                                }
                            }}
                            >
                            Contact Us
                        </Button>
                    </ButtonContainer>
                </Grid>
            </Grid>
        </GradientBox>
    )
}

export default Mission;