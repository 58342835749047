import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, Card, CardContent, CircularProgress } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeHighlight from 'rehype-highlight';
import rehypeRaw from 'rehype-raw';
import { styled } from '@mui/system';
import 'highlight.js/styles/default.css'; // Import a CSS file for syntax highlighting


const MarkdownContainer = styled('div')({
  lineHeight: '1.6', // Adjust line height for readability
  '& p': {
    marginBottom: '1.5em', // Add spacing between paragraphs
  },
  '& h1, & h2, & h3, & h4, & h5, & h6': {
    marginTop: '1.5em', // Add spacing before headings
    marginBottom: '0.5em', // Add spacing after headings
  },
  '& ul, & ol': {
    marginBottom: '1.5em', // Add spacing after lists
  },
  '& blockquote': {
    marginBottom: '1.5em', // Add spacing after blockquotes
    paddingLeft: '1em',
    borderLeft: '0.25em solid #dfe2e5',
    color: '#6a737d',
    fontStyle: 'italic',
  },
});

const ImageContainer = styled('div')({
  marginBottom: '1.5em', // Add spacing below the image
});

function BlogPost() {
  const { blogName } = useParams();
  const [post, setPost] = useState(null);

  useEffect(() => {
    const fetchPost = async () => {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/blogs/${blogName}`);
      const data = await response.json();
      setPost(data);
    };

    fetchPost();
  }, [blogName]);

  return (
    <div style={{ width: '95%', margin: '0 auto' }}>
      {post ? (
        <Card>
          <CardContent>
            <ImageContainer>
              <img src={post.imageUrl} alt={post.title} style={{ width: '100%', height: 'auto' }} />
            </ImageContainer>
            <Typography variant="h2" component="h1" gutterBottom>
              {post.title}
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              {post.date}
            </Typography>
            <MarkdownContainer>
              <ReactMarkdown
                remarkPlugins={[remarkGfm]}
                rehypePlugins={[rehypeRaw, rehypeHighlight]}
              >
                {post.content}
              </ReactMarkdown>
            </MarkdownContainer>
          </CardContent>
        </Card>
      ) : (
        <CircularProgress />
      )}
    </div>
  );
}

export default BlogPost;