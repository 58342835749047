import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Button, TextField, Grid, Typography } from '@mui/material';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const BlogEditor = () => {
    const { blogId } = useParams();  // Get blogId from URL
    const [content, setContent] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [formData, setFormData] = useState({
        title: '',
        date: '',
        summary: '',
        imageUrl: '',
        content: ''
    });

    useEffect(() => {
        if (blogId) {
            fetch(`${process.env.REACT_APP_API_URL}/posts/${blogId}`)
                .then(response => response.json())
                .then(data => {
                    setFormData({
                        title: data.title,
                        date: data.date,
                        summary: data.summary,
                        imageUrl: data.imageUrl,
                        content: data.content
                    });
                    setContent(data.content);
                    console.log(formData)
                });
        }

        const style = document.createElement('style');
        style.innerHTML = '.ck-editor__editable { height: 500px; overflow-y: auto; }';
        document.head.appendChild(style);

        return () => {
            document.head.removeChild(style);
        };
    }, [blogId]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    

    const handleSubmit = async (event) => {
        event.preventDefault();
        const endpoint = blogId ? `${process.env.REACT_APP_API_URL}/save-blog/${blogId}` : `${process.env.REACT_APP_API_URL}/save-blog/`;
        const data = new FormData(event.currentTarget);
        data.append('content', content); 
        
        try {
            const response = await fetch(endpoint, {
                method: 'POST',
                body: data,
            });

            if (response.ok) {
                setSubmitted(true);
                alert(blogId ? 'Blog updated successfully!' : 'Blog saved successfully!');
            } else {
                alert('Failed to save blog');
            }
        } catch (error) {
            console.error('Error submitting blog:', error);
            alert('An error occurred while submitting the blog');
        }
    };

    const handleDelete = async () => {
        if (!blogId) return;  // Just in case, although the button should only show when blogId exists
    
        const confirmDelete = window.confirm("Are you sure you want to delete this blog post?");
        if (confirmDelete) {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/delete-blog/${blogId}`, {
                    method: 'DELETE',
                });
    
                if (response.ok) {
                    alert('Blog post deleted successfully!');
                } else {
                    alert('Failed to delete the blog post.');
                }
            } catch (error) {
                console.error('Error deleting blog post:', error);
                alert('An error occurred while deleting the blog post.');
            }
        }
    };
    

    return (
        <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{
                width: '90%', 
                mx: 'auto', 
                mt: 1
            }}
        >
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <h2>{blogId ? 'Edit Blog' : 'Create a New Blog'}</h2>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        label="Blog Title"
                        name="title"
                        value={formData.title}
                        onChange={handleChange} 
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        label="Last Edited Date"
                        name="date"
                        value={formData.date}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        label="ImageUrl"
                        name="imageUrl"
                        value={formData.imageUrl}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        label="Summary"
                        multiline
                        rows={2}
                        name="summary"
                        value={formData.summary}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1" sx={{ mb: 2 }}>Content</Typography>
                    <CKEditor
                        editor={ClassicEditor}
                        data={content}
                        onChange={(event, editor) => {
                            const mainBlog = editor.getData();
                            setContent(mainBlog);
                        }}
                        config={{
                            placeholder: 'Write the content of blog here...',
                        }}
                    />

                </Grid>
            </Grid>
            <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled={submitted}
            >
                {blogId ? 'Update Blog Post' :'Create Blog Post'}
            </Button>
            {blogId && (
                <Grid item xs={12}>
                    <Button
                        fullWidth
                        variant="contained"
                        sx={{ mt: 1, mb: 2, backgroundColor: '#D32F2F', '&:hover': { backgroundColor: 'darkred' } }}
                        onClick={handleDelete}
                    >
                        Delete this Blog
                    </Button>
                </Grid>
            )}
        </Box>
    );
};

export default BlogEditor;
