import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import SpeedIcon from '@mui/icons-material/Speed';
import TuneIcon from '@mui/icons-material/Tune';
import GroupsIcon from '@mui/icons-material/Groups';

const Feature = () => {
  return (
    <Box sx={{ py: 16, px: 16 }}>
      <Grid container spacing={4} justifyContent="center">
        <Grid item xs={12} sm={4}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <SpeedIcon sx={{fontSize: "5rem", marginBottom: '3rem', color: '#3a1c71'}}/>
            <Typography variant="h5" align="center" gutterBottom>
              Accurate with Speed
            </Typography>
            <Typography variant="body1" align="center" color="textSecondary">
              Explicit and Automatic Workflow <br />
              Intelligent Reasoning by AI Agent<br />
              Step-by-Step Guided
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <TuneIcon sx={{fontSize: "5rem", marginBottom: '3rem', color: '#3a1c71'}}/>
            <Typography variant="h5" align="center" gutterBottom>
              Deeply Customizable
            </Typography>
            <Typography variant="body1" align="center" color="textSecondary">
              Integrated with Your SOP<br />
              Support Proprietary Data and Workflow<br />
              Support Model Fine-Tuning
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <GroupsIcon sx={{fontSize: "5rem", marginBottom: '3rem', color: '#3a1c71'}}/>
            <Typography variant="h5" align="center" gutterBottom>
              Design for All
            </Typography>
            <Typography variant="body1" align="center" color="textSecondary">
              No Coding, No Prompting <br />
              Support Excel, Word, PowerPoint, CSV<br />
              Support Google Shared Drive
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Feature;