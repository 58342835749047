import React from 'react';
import { Box, Grid, Typography, styled, Button } from '@mui/material';
import productScreenshot from '../assets/product.png'; // Make sure to replace with the actual path to your product screenshot
import { Link } from 'react-router-dom'

const GradientBox = styled(Box)({
    minHeight: '70vh',
    display: 'flex',
    alignItems: 'center',
    background: 'linear-gradient(135deg, #3a1c71, #d76d77, #ffaf7b)', // Blue-purple gradient
    color: '#fff',
    padding: '2rem',
});

const TextContainer = styled(Box)({
    maxWidth: '600px',
    margin: '0 auto',
});

const Product = () => {
    return (
        <GradientBox>
            <Grid container spacing={4} alignItems="center">
                <Grid item xs={12} md={6}>
                    <TextContainer>
                        <Typography variant="h4" component="h1" sx={{ fontWeight: 'bold', mb: 2 }}>
                            Verify Data Quickly, Easily, and Precisely
                        </Typography>
                        <Typography variant="h6" component="h2">
                            With Our Data Verification Tool
                        </Typography>
                        <Button 
                            component={Link} 
                            to={'/contact'}
                            variant='contained'
                            sx={{
                                mt: 4,
                                mr: 2,
                                px: 4, 
                                py: 1,
                                fontSize: '0.9rem',
                                textTransform: 'capitalize',
                                borderRadius: 0,
                                borderColor: '#14192d',
                                color: '#fff',
                                backgroundColor: '#7986cb',
                                "&&:hover": {
                                    backgroundColor: "#2c387e"
                                },
                                "&&:focus": {
                                    backgroundColor: "#2c387e"
                                }
                            }}
                            >
                            Free Trial
                        </Button>
                    </TextContainer>
                </Grid>
                <Grid item xs={12} md={6}>
                    <img src={productScreenshot} alt="Product Screenshot" style={{ width: '100%', borderRadius: '8px' }} />
                </Grid>
            </Grid>
        </GradientBox>
    );
};

export default Product;
