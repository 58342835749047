import React from 'react';
import { Card, CardContent, Typography, Grid, Avatar, Box, Link } from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import AvatarKe from '../assets/avatar_ke_xu.JPG'
import AvatarZwea from '../assets/avatar_zwea_htet.jpeg'

const teamMembers = [
  {
    name: 'Dr. Ke Xu',
    title: 'Founder & CEO',
    location: 'Berkeley, CA',
    avatar: AvatarKe,
    linkedin: 'https://www.linkedin.com/in/ke-xu-13930529/',
  },
  {
    name: 'Zwea Htet',
    title: 'Founding Engineer',
    location: 'Berkeley, CA',
    avatar: AvatarZwea,
    linkedin: 'https://www.linkedin.com/in/zweahtet/',
  }
];

const TeamCard = ({ member }) => (
  <Card sx={{ maxWidth: 345, margin: 'auto', backgroundColor: 'rgba(255, 255, 255, 0.8)', boxShadow: 'none' }}>
    <Box sx={{ display: 'flex', justifyContent: 'center', padding: 2 }}>
      <Avatar alt={member.name} src={member.avatar} sx={{ width: 200, height: 200}} />
    </Box>
    <CardContent>
      <Typography variant="h6" component="div" align="center">
        {member.name}
      </Typography>
      <Typography variant="subtitle1" color="text.secondary" align="center">
        {member.title}
      </Typography>
      <Typography variant="body2" color="text.secondary" align="center">
        {member.location}
      </Typography>
      {member.linkedin && (
        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
          <Link href={member.linkedin} target="_blank" rel="noopener">
            <LinkedInIcon />  
          </Link>
        </Box>
      )}
    </CardContent>
  </Card>
);

const Team = () => (
  <Box sx={{ flexGrow: 1, padding: 5, backgroundColor: 'white', mt: 4}}>
    <Typography variant="h4" component="div" align="center" gutterBottom>
      Founding Team
    </Typography>
    <Typography variant="body1" component="div" align="center" color="text.secondary" gutterBottom>
      We are all UC Berkeley bears! 
      Daicus is backed by Berkeley SkyDeck Incubator.
    </Typography>
    <Grid container spacing={4} justifyContent="center" sx={{mt: 3}}>
      {teamMembers.map((member, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <TeamCard member={member} />
        </Grid>
      ))}
    </Grid>
  </Box>
);

export default Team;
